import { SOLANA_RPC_URL } from '../global.config'
import axios from 'axios'

const getNFTsForWalletAndCreator = async (walletAddress, creatorAddress) => {
  try {
    const data = await axios.post(SOLANA_RPC_URL, {
      "jsonrpc": "2.0",
      "id": "1",
      "method": "searchAssets",
      "params": {
        "ownerAddress": walletAddress,
        "creatorAddress": creatorAddress,
        "page": 1,
        "limit": 1000
      }
    })
    return (data.data.result?.items || []).filter((item) => !Boolean(item.burnt))
  } catch (error) {
    console.log('Error while getting NFTs', error)
    return []
  }
}

const getNFTsForWalletAndFirstCreator = async (walletAddress, creatorAddress) => {
  try {
    const nftArray = await getNFTsForWalletAndCreator(walletAddress, creatorAddress)
    const availableNFTs = nftArray.flat().filter(item => !!item.content.metadata.attributes).map((item) => {
      // we need to get the tribe of the nft
      const tribeData = item.content.metadata.attributes.find((attr) => attr.trait_type.toLowerCase() === 'tribe')

      return {
        mint: item.id,
        name: item.content.metadata.name,
        image: item.content.files[0].uri,
        tribe: tribeData?.value
      }
    })
    return availableNFTs
  } catch (error) {
    console.log('Error while getting NFTs', error)
    return []
  }
}

export { getNFTsForWalletAndFirstCreator }